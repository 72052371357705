import Popup from './Popup';
import PopupTxnCompleted from './PopupTxnCompleted';
import PopupTxnError from './PopupTxnError';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies } from '../../../../utils/styles';
import { customFormat, formatter } from '../../../../utils/numbers';
import PopupTxnCompletedWarUpgrades from './PopupTxnCompletedWarUpgrades';
import PopupSelectAugmentComplete from './PopupSelectAugmentComplete';

const { width, height } = configs;

class PopupProcessing extends Popup {
  onCompleted;
  constructor(scene, { sound, completedEvent, completedIcon, description, failedIcon, onCompleted }) {
    super(scene, 'popup-small', { title: 'Almost done', openOnCreate: false });
    this.onCompleted = onCompleted;

    const startingY = this.popup.y - this.popup.height / 2;
    const iconY = startingY + 350;
    const titleY = iconY + 160;
    const descriptionY = titleY + 320;

    this.loading = true;
    this.completedIcon = completedIcon;
    if (sound) this.doneSound = scene.sound.add(sound, { loop: false });
    this.icon = scene.add.image(width / 2, iconY, 'icon-loading');
    this.iconDone = scene.add.sprite(width / 2, iconY, completedIcon);
    this.iconDone.setVisible(false);
    if (failedIcon) {
      this.iconFail = scene.add.image(width / 2, iconY, failedIcon);
      this.iconFail.setVisible(false);
      this.add(this.iconFail);
    }

    this.title = scene.add
      .text(width / 2, titleY, 'Processing...', {
        fontSize: '52px',
        color: colors.brown,
        fontFamily: fontFamilies.bold,
        align: 'center',
      })
      .setOrigin(0.5, 0);
    const descriptionContainer = scene.add.image(width / 2, descriptionY, 'text-container');
    this.description = scene.add
      .text(width / 2, descriptionY, description, {
        fontSize: '52px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);

    this.add(this.icon);
    this.add(this.iconDone);
    this.add(this.title);
    this.add(descriptionContainer);
    this.add(this.description);

    this.loadingAnimation = scene.tweens.add({
      targets: this.icon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.play();

    scene.events.on(completedEvent, (data) => {
      const status = data?.status;
      const message = data?.message;
      const title = data?.title;
      const txnHash = data?.txnHash;
      const action = data?.action;
      const iconError = data?.iconError;
      const code = data?.code;
      this.loading = false;
      // icons
      this.loadingAnimation.pause();
      this.icon.setVisible(false);

      if (status === 'failed') {
        this.popupTxnCompleted = new PopupTxnError({
          scene,
          code,
          icon: iconError,
          title,
          description: message,
          action: action,
          txnHash: txnHash,
        });
        scene.add.existing(this.popupTxnCompleted);
        this.close();
      } else {
        this.setTitle('Success');
        this.title.text = 'All done!';
        this.description.text = message ?? 'Your reputation & earnings \n are updated';
        this.doneSound?.play();
        this.iconDone.setVisible(true);

        const simulatorBuyEvents = [
          'simulator-buy-gangster-completed',
          'simulator-buy-goon-completed',
          'simulator-upgrade-safehouse-completed',
          'simulator-buy-thug',
        ];
        const hasTxnHashEvents = [
          ...simulatorBuyEvents,
          's-buy-gangster-completed',
          's-buy-goon-completed',
          's-war-upgrade-completed',
          's-roll-augments-completed',
          's-select-augment-completed',
          's-buy-starter-pack-completed',
          's-upgrade-safehouse-completed',
          's-deposit-nft-completed',
          's-withdraw-token-completed',
          's-withdraw-eth-completed',
          's-withdraw-nft-completed',
          's-swap-completed',
        ];

        // deprecated, can use PopupTxnCompleted without passing a txnHash
        if (!hasTxnHashEvents.includes(completedEvent)) {
          const buttonGreat = new TextButton(
            scene,
            width / 2,
            height / 2 + this.popup.height / 2 - 20,
            'button-blue',
            'button-blue-pressed',
            this.close,
            'Great',
            { fontSize: '82px', sound: 'close' }
          );
          this.add(buttonGreat);

          return;
        }

        const { txnHash, txnHash2, amount, description, token } = data;
        let title = '',
          desc = '';

        switch (completedEvent) {
          case 's-buy-gangster-completed':
          case 'simulator-buy-gangster-completed':
            title = `${formatter.format(amount)} Gangster${amount > 1 ? 's' : ''}`;
            desc = 'Gangsters hired successfully.';
            break;
          case 's-buy-goon-completed':
          case 'simulator-buy-goon-completed':
            title = `${formatter.format(amount)} Goon${amount > 1 ? 's' : ''}`;
            desc = 'Goons hired successfully.';
            break;
          case 's-upgrade-safehouse-completed':
          case 'simulator-upgrade-safehouse-completed':
            title = `${formatter.format(amount)} Safehouse${amount > 1 ? 's' : ''}`;
            desc = 'Safehouse bought successfully.';
            break;
          case 's-war-upgrade-completed':
            const pistolString = `${customFormat(data.pistolQuantity, 2)} pistol${data.pistolQuantity > 1 ? 's' : ''}`;
            const shieldString = `${customFormat(data.shieldQuantity, 2)} shield${data.shieldQuantity > 1 ? 's' : ''}`;

            if (data.pistolQuantity && data.shieldQuantity) title = `${pistolString} + ${shieldString}`;
            else title = data.pistolQuantity ? pistolString : shieldString;
            desc = 'Upgrades purchased successfully.';
            this.popupTxnCompleted = new PopupTxnCompletedWarUpgrades(scene, this.completedIcon, title, desc, {
              onCompleted,
              pistolTxnHash: txnHash,
              shieldTxnHash: txnHash2,
            });
            scene.add.existing(this.popupTxnCompleted);
            this.onCompleted = null;
            this.close();
            return;
          case 's-roll-augments-completed':
            this.close();
            scene.popupAugments.open();
            return;
          case 's-select-augment-completed':
            this.popupTxnCompleted = new PopupSelectAugmentComplete(scene);
            scene.add.existing(this.popupTxnCompleted);
            this.onCompleted = null;
            this.close();
            this.popupTxnCompleted.showReward(data.augment);
            return;
          case 's-buy-starter-pack-completed':
            const goonString = `${customFormat(data.goonsQuantity, 2)} goon${data.goonsQuantity > 1 ? 's' : ''}`;
            const gangsterString = `${customFormat(data.gangstersQuantity, 2)} gangster${
              data.gangstersQuantity > 1 ? 's' : ''
            }`;
            const thugString = `${customFormat(data.thugsQuantity, 2)} thug${data.thugsQuantity > 1 ? 's' : ''}`;
            const strings = [];
            if (data.goonsQuantity) strings.push(goonString);
            if (data.gangstersQuantity) strings.push(gangsterString);
            if (data.thugsQuantity) strings.push(thugString);
            title = strings.join(' + ');
            desc = 'Pack purchased successfully.';
            break;
          case 's-deposit-nft-completed':
            title = `${formatter.format(amount)} NFT${amount > 1 ? 's' : ''}`;
            desc = 'Staking completed.';
            break;
          case 's-withdraw-token-completed':
            title = `${formatter.format(amount)} $GOLD`;
            desc = 'Withdraw completed.';
            break;
          case 's-withdraw-eth-completed':
            title = `${formatter.format(amount)} ETH`;
            desc = 'Withdraw completed.';
            break;
          case 's-withdraw-nft-completed':
            title = `${formatter.format(amount)} NFT${amount > 1 ? 's' : ''}`;
            desc = 'Withdraw completed.';
            break;
          case 's-swap-completed':
            title = `${formatter.format(amount)} ${token}`;
            desc = description;
            break;
        }

        this.popupTxnCompleted = new PopupTxnCompleted(scene, this.completedIcon, title, desc, txnHash, {
          onCompleted,
          hideTxnHash: simulatorBuyEvents.includes(completedEvent) || !txnHash,
        });
        scene.add.existing(this.popupTxnCompleted);
        this.onCompleted = null;
        this.close();
      }
    });
  }

  cleanup() {
    this.onCompleted?.();
  }

  initLoading(description) {
    this.loading = true;
    this.description.text = description;
    this.setTitle(`Almost done`);
    this.title.text = 'Processing...';
    this.loadingAnimation.resume();
    this.icon.setVisible(true);
    this.iconDone.setVisible(false);
    if (this.iconFail) this.iconFail.setVisible(false);
    this.setVisible(true);
  }

  updateCompletedIcon(newCompletedIcon) {
    this.completedIcon = newCompletedIcon;
  }
}

export default PopupProcessing;
