import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Phaser from 'phaser';
import CircleMaskImagePlugin from 'phaser3-rex-plugins/plugins/circlemaskimage-plugin.js';
import { useSnackbar } from 'notistack';
import { usePrivy, useFundWallet } from '@privy-io/react-auth';
import { useShallow } from 'zustand/react/shallow';
import * as Sentry from '@sentry/react';
import moment from 'moment';

import useUserStore from '../../stores/user.store';
import useSystemStore from '../../stores/system.store';
import useSettingStore from '../../stores/setting.store';
import usePrivyStore from '../../stores/privy.store';
import useModalStore from '../../stores/modal.store';

import {
  getRank,
  getWarHistory,
  getWarHistoryDetail,
  updateBalance,
  unlinkTwitter,
  getXCsrfToken,
  getReward,
  getAirdropWalletList,
  claimThug,
  claimFreeThug,
  applyInviteCode,
  getReferralDiscount,
  getUserRewards,
  completeTutorial,
} from '../../services/user.service';
import {
  create,
  validate,
  claimToken,
  getWorkerPrices,
  getBuildingPrices,
  getMachinePrices,
  validateDailySpin,
  buyAssetsWithXToken,
  getSpinTxns,
  validateGameTxn,
  buyThugsWithPoorToken,
  rollbackRerollAugments,
} from '../../services/transaction.service';
import {
  getUPointLeaderboard,
  getNextWarSnapshotUnixTime,
  updateLastTimeSeenGangWarResult,
  updateUserWarDeployment,
  getLeaderboard,
  getRaidPointLeaderboard,
} from '../../services/gamePlay.service';
import {
  getLatestWar,
  getUserListToAttack,
  getUserDetailToAttack,
  getLatestWarResult,
} from '../../services/war.service';
import { getOauthRequestToken } from '../../services/twitter.service';
import {
  getAuctionItems,
  createBidding,
  getBiddingHistory,
  getAuctionItemHistory,
  getAuctionItemHistoryDetail,
  getAuctionEndTime,
} from '../../services/auction.service';
import { getUserAugments, selectAugment } from '../../services/augment.service';
import { calculateHouseLevel, calculateSpinPrice } from '../../utils/formulas';
import useSmartContract from '../../hooks/useSmartContract';
import purchaseConfig from '../../assets/jsons/purchaseConfig.json';

import gameConfigs, { windowHeight } from './configs/configs';
import LoadingScene from './scenes/LoadingScene';
import MainScene from './scenes/MainScene';
import TutorialScene from './scenes/TutorialScene';
import AuctionScene from './scenes/AuctionScene';

import useWagmi from '../../hooks/useWagmi';
import useUserWallet from '../../hooks/useUserWallet';
import usePriceTracking from '../../hooks/usePriceTracking';
import useSimulator from '../../hooks/useSimulator';
import { logAnalyticsEvent } from '../../configs/firebase.config';
import environments from '../../utils/environments';
import { blastSepolia, blast } from 'viem/chains';

const { width, height, screenRatio } = gameConfigs;
const { GAME_VERSION, ENVIRONMENT } = environments;
const MILISECONDS_IN_A_DAY = 86400 * 1000;
const MILLISECONDS_PER_MINUTE = 60 * 1000;
const cacheTime = 1 * 60 * 1000;

const chain = ENVIRONMENT === 'production' ? blast : blastSepolia;

const lineBreakMessage = (message) => {
  const MAX_WORD_LENGTH = 20;
  if (message.length <= MAX_WORD_LENGTH) return message;

  const words = message.trim().split(' ');
  const brokenWords = [words[0]];
  for (let i = 1; i < words.length; i++) {
    const newWord = words[i];
    const lastWord = brokenWords.at(-1);
    if (lastWord.length + newWord.length + 1 <= MAX_WORD_LENGTH) {
      brokenWords[brokenWords.length - 1] = brokenWords.at(-1) + ` ${newWord}`;
    } else {
      brokenWords.push(newWord);
    }
  }

  return brokenWords.join('\n');
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const handleError = (err) => {
  try {
    if (err.message === 'The user rejected the request') {
      return { code: '4001', message: 'The user rejected\nthe request' };
    } else {
      console.error(err);

      const message = err.message;
      const code = err.code?.toString();

      if (message === 'Network Error') {
        return { code: '12002', message: 'Network Error' };
      }

      if (message.includes('replacement fee too low')) return { code: '4001', message: 'Replacement fee\ntoo low' };

      if (message.includes('Transaction reverted without a reason string'))
        return { code: '4001', message: 'Transaction reverted' };

      if (message.includes('Request failed with status code 422')) return { code: '4001', message: 'Request failed' };

      if (message.includes('invalid address or ENS name')) return { code: '4001', message: 'Invalid address\nor ENS' };

      if (message.includes('User exited before wallet could be connected'))
        return { code: '4001', message: 'User exited' };

      if (message.includes('transaction failed')) return { code: '4001', message: 'Transaction failed' };

      if (message.includes('missing response')) return { code: '4001', message: 'Missing response' };

      if (message.includes('Cannot redefine property: ethereum'))
        return { code: '4001', message: 'Cannot redefine\nethereum' };

      if (message.includes('insufficient funds for intrinsic transaction cost'))
        return { code: 'INSUFFICIENT_FUNDS', message: 'Insufficient ETH' };

      if (code === 'UNPREDICTABLE_GAS_LIMIT' || code === '-32603') {
        if (message === 'Wallet has insufficient funds for this transaction.')
          return { code: 'INSUFFICIENT_FUNDS', message: 'Insufficient Gas' };
        if (err?.error?.reason && err.error?.reason.includes('execution reverted:')) {
          const error = err.error?.reason.replace('execution reverted: ', '');
          return { code: 'UNPREDICTABLE_GAS_LIMIT', message: error ? lineBreakMessage(error) : 'TRANSACTION FAILED' };
        }

        return { code: 'UNPREDICTABLE_GAS_LIMIT', message: 'TRANSACTION FAILED' };
      }

      if (code === 'INSUFFICIENT_FUNDS') return { code: 'INSUFFICIENT_FUNDS', message: 'INSUFFICIENT ETH' };

      if (code === 'INVALID_ARGUMENT') return { code: 'INVALID_ARGUMENT', message: 'INVALID ARGUMENT' };

      if (code === 'NETWORK_ERROR') return { code: 'NETWORK_ERROR', message: 'Network Error' };

      Sentry.captureException(err);
      return { code: '4001', message: 'Unknown Error' };
    }
  } catch (err) {
    Sentry.captureException(err);
    return { code: '4001', message: 'Unknown Error' };
  }
};

const Game = () => {
  const { fundWallet } = useFundWallet();
  const { enqueueSnackbar } = useSnackbar();
  const { userWallet } = useUserWallet();
  const cachedRef = useRef({
    auctionItemHistory: {},
    auctionItemHistoryDetail: {},
  });
  const gameRef = useRef();
  const gameLoaded = useRef();
  const gameEventListened = useRef();
  const [loaded, setLoaded] = useState(false);
  const profile = useUserStore((state) => state.profile);
  const gamePlay = useUserStore((state) => state.gamePlay);
  const reloadWarDeployment = useUserStore((state) => state.reloadWarDeployment);
  const activeSeason = useSystemStore((state) => state.activeSeason);
  const augmentConfigs = useSystemStore((state) => state.augmentConfigs);
  const activeSeasonId = useSystemStore(useShallow((state) => state.activeSeason?.id));
  const setOpenMoonPay = useModalStore((state) => state.setOpenMoonPay);

  const simulator = useSimulator();

  const configs = useSystemStore((state) => state.configs);
  const market = useSystemStore((state) => state.market);
  const templates = useSystemStore((state) => state.templates);
  const estimatedGas = useSystemStore((state) => state.estimatedGas);
  const sound = useSettingStore((state) => state.sound);
  const toggleSound = useSettingStore((state) => state.toggleSound);
  const setOnlineListener = useSettingStore((state) => state.setOnlineListener);
  const setIsCustomContainer = usePrivyStore((state) => state.setIsCustomContainer);
  const {
    getNFTBalance,
    getETHBalance,
    withdrawToken,
    withdrawETH,
    withdrawNFT,
    stakeNFT,
    buyMachine,
    buyGoon,
    buyPistol,
    buyShield,
    buyAugmentRoll,
    dailySpin,
    buyStartPack,
    swapEthToToken,
    swapTokenToEth,
    convertEthInputToToken,
    convertTokenInputToEth,
  } = useSmartContract();
  const { connectors, connect, disconnect, signMessage } = useWagmi();
  const { ready, authenticated, user, exportWallet: exportWalletPrivy, logout } = usePrivy();
  const [userCanReload, setUserCanReload] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [startLoadingTime, setStartLoadingTime] = useState(Date.now());
  const [showBg, setShowBg] = useState(true);
  const {
    listeningWorker,
    listeningBuilding,
    listeningMachine,
    listeningThug,
    enableWorkerSalesTracking,
    disableWorkerSalesTracking,
    enableBuildingSalesTracking,
    disableBuildingSalesTracking,
    enableMachineSalesTracking,
    disableMachineSalesTracking,
    enableThugSalesTracking,
    disableThugSalesTracking,
  } = usePriceTracking();

  useLayoutEffect(() => {
    setIsCustomContainer(false);
  }, []);

  const reloadUserWarDeployment = async () => {
    try {
      await reloadWarDeployment();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const { appVersion, appReloadThresholdInSeconds } = configs || {};
  const { ethPriceInUsd, tokenPrice, nftPrice } = market || {};

  // Check that your user is authenticated
  const isAuthenticated = useMemo(() => ready && authenticated, [ready, authenticated]);

  const {
    username,
    address,
    socials,
    avatarURL,
    avatarURL_big,
    tokenBalance,
    ETHBalance,
    inviteCode,
    referralCode,
    referralTotalReward,
    referralTotalDiscount,
    referralProgramType,
  } = profile || {
    tokenBalance: 0,
    ETHBalance: 0,
    inviteCode: '',
    referralCode: '',
    referralTotalReward: 0,
    referralTotalDiscount: 0,
    referralProgramType: 'default',
  };

  const {
    xTokenBalance,
    numberOfMachines,
    numberOfAvailableMachines,
    numberOfMachinesFromMidSeason,
    numberOfWorkers,
    numberOfWorkersFromMidSeason,
    numberOfBuildings,
    numberOfBuildingsFromMidSeason,
    numberOfPistols,
    numberOfShields,
    numberOfThugs,
    networth,
    raidPoint,
    isWhitelisted,
    whitelistAmountMinted,
    warDeployment,
    uPointReward,
    blastPointReward,
    twitterVerified,
    latestWarChain,
    packsPurchased,
  } = gamePlay || {
    xTokenBalance: 0,
    numberOfMachines: 0,
    numberOfWorkers: 0,
    numberOfBuildings: 0,
    numberOfThugs: 0,
    numberOfPistols: 0,
    numberOfShields: 0,
    networth: 0,
    raidPoint: 0,
    whitelistAmountMinted: 0,
    warDeployment: {
      numberOfMachinesToEarn: 0,
      numberOfMachinesToAttack: 0,
      numberOfMachinesToDefend: 0,
      attackUserId: null,
    },
    uPointReward: 0,
    blastPointReward: 0,
    twitterVerified: false,
    latestWarChain: { result: null, numberOfWars: 0 },
    packsPurchased: {},
  };
  const {
    machine,
    worker,
    thug,
    building,
    pistol,
    shield,
    houseLevels,
    prizePoolEth,
    prizePoolToken,
    blastGoldReward,
    xUReward,
    prizePoolConfig,
    midSeasonSnapshotTaken,
    auctionState: { nextAuctionPrizePool = 0 } = {},
    spinConfig: { spinRewards, tokenReputationRewardMutiplier, costReputationMultiplier, basePrice },
  } = activeSeason || {
    machine: { dailyReward: 0, basePrice: 0, whitelistPrice: 0, networthBase: 0, networthPerDay: 0 },
    worker: {
      basePrice: 0,
      targetDailyPurchase: 1,
      targetPrice: 0,
      dailyReward: 0,
      networthBase: 0,
      networthPerDay: 0,
    },
    thug: {
      basePrice: 0,
      targetDailyPurchase: 1,
      targetPrice: 0,
      dailyReward: 0,
      networthBase: 0,
      networthPerDay: 0,
    },
    building: {
      basePrice: 0,
      targetDailyPurchase: 1,
      targetPrice: 0,
      dailyReward: 0,
      networthBase: 0,
      networthPerDay: 0,
    },
    pistol: {
      price: 0,
      maxPerBatch: 0,
      attackPoint: 0,
    },
    shield: {
      price: 0,
      maxPerBatch: 0,
      defencePoint: 0,
    },
    buildingSold: 0,
    workerSold: 0,
    machineSold: 0,
    houseLevels: [],
    prizePoolEth: 0,
    prizePoolToken: 0,
    blastGoldReward: 0,
    xUReward: 0,
    auctionState: { nextAuctionPrizePool: 0 },
    prizePoolConfig: {},
    spinConfig: { spinRewards: [], tokenReputationRewardMutiplier: 0, costReputationMultiplier: 1, basePrice: 500 },
  };

  const dailyXToken = numberOfMachines * gamePlay?.machine?.dailyReward + numberOfWorkers * worker.dailyReward;

  useEffect(() => {
    setStartLoadingTime(Date.now());
  }, []);

  useEffect(() => {
    if (!appReloadThresholdInSeconds || userCanReload) return;
    const elapsedTime = Date.now() - startLoadingTime;
    const remainingTime = appReloadThresholdInSeconds * 1000 - elapsedTime;

    if (remainingTime <= 0) {
      setUserCanReload(true);
    } else {
      const timer = setTimeout(() => {
        setUserCanReload(true);
      }, remainingTime);

      return () => clearTimeout(timer);
    }
  }, [userCanReload, startLoadingTime, appReloadThresholdInSeconds]);

  const reloadBalance = async () => {
    try {
      await updateBalance();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    } finally {
      gameRef.current?.events.emit('refresh-eth-balance-completed');
    }
  };

  const transfer = async ({ amount, address, tokenType }) => {
    let web3Withdraw, txnStartedEvent, txnCompletedEvent;
    switch (tokenType) {
      case 'GOLD':
        web3Withdraw = withdrawToken;
        txnStartedEvent = 'g-withdraw-token-started';
        txnCompletedEvent = 'g-withdraw-token-completed';
        break;
      case 'ETH':
        web3Withdraw = withdrawETH;
        txnStartedEvent = 'g-withdraw-eth-started';
        txnCompletedEvent = 'g-withdraw-eth-completed';
        break;
      case 'NFT':
        web3Withdraw = withdrawNFT;
        txnStartedEvent = 'g-withdraw-nft-started';
        txnCompletedEvent = 'g-withdraw-nft-completed';
        break;
    }
    try {
      if (!web3Withdraw) throw new Error(`Invalid tokenType. Must be one of 'ETH' | 'GOLD' | 'NFT'`);
      gameRef.current?.events.emit(txnStartedEvent);

      const value = Number(amount);
      let txnId;
      if (tokenType === 'ETH') {
        const res = await create({ type: 'withdraw', token: tokenType, value, to: address });
        txnId = res.data.id;
      }
      const receipt = await web3Withdraw(address, value);
      if (receipt) {
        gameRef.current?.events.emit(txnCompletedEvent, { amount, txnHash: receipt.transactionHash });
        if (receipt.status === 1) {
          if (txnId && ['ETH', 'GOLD'].includes(tokenType))
            await validate({ transactionId: txnId, txnHash: receipt.transactionHash });
          if (tokenType === 'NFT') {
            validateGameTxn({ txnHash: receipt.transactionHash, type: 'withdraw-machine' }).catch((err) => {
              console.error(err);
              Sentry.captureException(err);
            });
          }
        }
      }
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit(txnCompletedEvent, {
        status: 'failed',
        code,
        message,
        amount,
        txnHash: '',
      });
    }
  };

  const stake = async (amount) => {
    try {
      gameRef.current?.events.emit('g-deposit-nft-started');

      const receipt = await stakeNFT(address, amount);
      if (receipt.status === 1) {
        validateGameTxn({ txnHash: receipt.transactionHash, type: 'deposit-machine' }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        gameRef.current?.events.emit('g-deposit-nft-completed', { amount, txnHash: receipt.transactionHash });
      }
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-deposit-nft-completed', {
        status: 'failed',
        code,
        message,
      });

      console.error(err);
      Sentry.captureException(err);
    }
  };

  const buyBuilding = async ({ quantity }) => {
    try {
      await buyAssetsWithXToken({ type: 'building', amount: quantity });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const buyWorker = async ({ quantity, token }) => {
    try {
      if (token === 'xGREED') {
        await buyAssetsWithXToken({ type: 'worker', amount: quantity });
      } else {
        const res = await create({ type: 'buy-worker', amount: quantity, token });
        const { id, amount, value, time, referrerAddress, referralBonusAmount, nonce, signature, lastB } = res.data;
        const receipt = await buyGoon({
          amount,
          value: token === 'GREED' ? value : 0,
          lastB,
          time,
          referrerAddress,
          referralBonusAmount,
          nonce,
          signature,
        });

        if (receipt.status !== 1) throw new Error('Transaction failed');
        validateGameTxn({ txnHash: receipt.transactionHash, type: 'buy-worker' }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        return receipt.transactionHash;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const buyGangster = async (quantity, mintFunction) => {
    try {
      const res = await create({ type: 'buy-machine', amount: quantity, mintFunction });
      const { id, amount, value, time, lastB, referrerAddress, referralBonusAmount, nonce, signature } = res.data;
      const receipt = await buyMachine({
        amount,
        value,
        time,
        lastB,
        referrerAddress,
        referralBonusAmount,
        nonce,
        signature,
      });
      if (receipt.status === 1) {
        validateGameTxn({ txnHash: receipt.transactionHash, type: 'buy-machine' }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        return receipt.transactionHash;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const buyWarUpgrades = async ({ quantity, type, token }) => {
    if (!quantity) return;
    try {
      if (token === 'xGREED') {
        await buyAssetsWithXToken({ type, amount: quantity });
      } else {
        const res = await create({ type: `buy-${type}`, amount: quantity, token });
        const { amount, value, time, referrerAddress, referralBonusAmount, nonce, signature, lastB } = res.data;
        const buyFunction = type === 'pistol' ? buyPistol : buyShield;
        const receipt = await buyFunction({
          amount,
          value: token === 'GREED' ? value : 0,
          lastB,
          time,
          referrerAddress,
          referralBonusAmount,
          nonce,
          signature,
        });

        if (receipt.status !== 1) throw new Error('Transaction failed');
        validateGameTxn({ txnHash: receipt.transactionHash, type: `buy-${type}` }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        return receipt.transactionHash;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const buyStarterPack = async ({ packId }) => {
    if (!packId) return;
    let txnHash;

    try {
      const res = await create({ type: `buy-starter-pack`, packId });
      const { contractPackId, value, time, nonce, signature } = res.data;
      const receipt = await buyStartPack({
        packId: contractPackId,
        value,
        time,
        nonce,
        signature,
      });
      txnHash = receipt.transactionHash;

      if (receipt.status !== 1) throw new Error('Transaction failed');
      validateGameTxn({ txnHash, type: `buy-starter-pack` }).catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });

      return txnHash;
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw err;
    }
  };

  const rollAugments = async ({ isReroll }) => {
    let transactionId, txnHash;
    try {
      const type = isReroll ? 'reroll-augments' : 'roll-augments';
      const res = await create({ type });
      const { id, value, time, lastB, referrerAddress, referralBonusAmount, nonce, signature } = res.data;
      transactionId = id;
      const receipt = await buyAugmentRoll({
        value,
        time,
        lastB,
        referrerAddress,
        referralBonusAmount,
        nonce,
        signature,
      });
      txnHash = receipt.transactionHash;
      if (receipt.status === 1) {
        await validateGameTxn({ txnHash: receipt.transactionHash, type }).catch((err) => {
          console.error(err);
          Sentry.captureException(err);
        });
        return receipt.transactionHash;
      }
    } catch (err) {
      if (isReroll && transactionId) {
        rollbackRerollAugments({ transactionId, txnHash }).catch((e) => {
          console.error(`Err while rollback augments reroll: ${e.message}`);
          Sentry.captureException(e);
        });
      }
      Sentry.captureException(err);
      console.error(err);
      throw err;
    }
  };

  const initDailySpin = async () => {
    let transactionId, txnHash;
    try {
      const res = await create({ type: 'daily-spin' });
      const { id, spinType, amount, value, lastSpin, time, nonce, signature } = res.data;
      transactionId = id;
      const receipt = await dailySpin({ spinType, amount, value, lastSpin, time, nonce, signature });
      txnHash = receipt.transactionHash;
      if (receipt.status !== 1) {
        throw new Error('Transaction daily spin failed');
      }
      const res1 = await validateDailySpin({ transactionId, txnHash });
      const { result } = res1.data;
      gameRef.current?.events.emit('g-spin-result', { destinationIndex: result });
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      throw err;
    }
  };

  const calculateNetworth = () => {
    if (!activeSeason || !gamePlay?.startNetworthCountingTime)
      return {
        networth: Math.floor(networth),
        networthPerDay: 0,
      };

    const end = Math.min(Date.now(), activeSeason?.estimatedEndTime.toDate().getTime());
    const diffInDays = (end - gamePlay.startNetworthCountingTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const networthPerDay =
      numberOfMachines * machine.networthPerDay +
      numberOfBuildings * building.networthPerDay +
      numberOfWorkers * worker.networthPerDay;
    let generatedNetworth = networthPerDay * diffInDays;
    if (activeSeason?.startTime?.toDate().getTime() > Date.now()) {
      generatedNetworth = 0;
    }

    const calculatedNetworth = Math.floor(networth + generatedNetworth);
    return {
      networth: calculatedNetworth,
      networthPerDay,
    };
  };

  // ref functions
  const onSeasonStatusChanged = useRef();
  onSeasonStatusChanged.current = () => {
    gameRef.current?.events.emit('g-set-season-status', { status: activeSeason?.status });
  };

  const updateAnimationAssetsRef = useRef();
  updateAnimationAssetsRef.current = () => {
    gameRef.current?.events.emit('g-set-animation-assets', {
      numberOfWorkers,
      numberOfMachines,
      numberOfThugs,
    });
  };

  const updatePoorTokenBalanceRef = useRef();
  updatePoorTokenBalanceRef.current = () => {
    if (!gamePlay || !activeSeason) return;
    const { thug } = activeSeason;
    const { startPoorTokenCountingTime, numberOfThugs, poorTokenBalance } = gamePlay;

    const now = Date.now();
    const startTime = startPoorTokenCountingTime.toDate().getTime();

    const diffInDays = (now - startTime) / MILISECONDS_IN_A_DAY;
    if (diffInDays < 0) return;

    const balance = poorTokenBalance + numberOfThugs * thug.dailyReward * diffInDays;

    gameRef.current?.events.emit('g-set-poor-token-balance', { balance });
  };

  const calculateClaimableRewardRef = useRef();
  calculateClaimableRewardRef.current = () => {
    if (!gamePlay?.startXTokenCountingTime) return;

    const diffInDays = (Date.now() - gamePlay.startXTokenCountingTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    let claimableReward = gamePlay.pendingXToken + diffInDays * dailyXToken;
    if (activeSeason?.startTime?.toDate().getTime() > Date.now()) {
      claimableReward = 0;
    }
    gameRef.current?.events.emit('g-set-claimable-reward', { reward: claimableReward });
    gameRef.current?.events.emit('g-claimable-reward-added');
  };

  const calculateNetworthHouseRef = useRef();
  calculateNetworthHouseRef.current = ({ isSimulator } = {}) => {
    if (isSimulator) {
      gameRef.current?.events.emit('g-set-networth-house', {
        networth: 0,
        level: 1,
      });
      return;
    }
    const { networth, networthPerDay } = calculateNetworth();
    const level = calculateHouseLevel(houseLevels, networth);

    gameRef.current?.events.emit('g-set-networth-house', {
      networth,
      networthPerDay,
      level,
    });
  };

  const updateDepositCodeRef = useRef();
  updateDepositCodeRef.current = () => {
    if (profile?.code) {
      gameRef.current.events.emit('g-set-deposit-code', profile.code);
    }
  };

  const updateETHBalanceRef = useRef();
  updateETHBalanceRef.current = async () => {
    try {
      const newBalance = await getETHBalance(address);
      if (newBalance !== ETHBalance) {
        reloadBalance();
      }
      gameRef.current.events.emit('g-set-eth-balance', { address, ETHBalance: newBalance });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      gameRef.current.events.emit('g-set-eth-balance', { address, ETHBalance });
    }
  };

  const updateBalanceRef = useRef();
  updateBalanceRef.current = () => {
    gameRef.current.events.emit('g-set-balances', { ETHBalance, tokenBalance });
  };

  const updateXTokenBalanceRef = useRef();
  updateXTokenBalanceRef.current = () => {
    gameRef.current.events.emit('g-set-x-token-balance', { balance: xTokenBalance });
  };

  const claimRef = useRef();
  claimRef.current = async ({ isSimulator } = {}) => {
    if (isSimulator) {
      gameRef.current?.events.emit('g-claim-completed', { amount: 15000 });
      gameRef.current.events.emit('g-set-x-token-balance', { balance: 15000 });
      return;
    }
    let amount;
    try {
      const res = await claimToken();
      amount = res.data.claimedAmount;
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
    gameRef.current?.events.emit('g-claim-completed', { amount });
  };

  const getWarDataRef = useRef();
  getWarDataRef.current = () => {
    gameRef.current?.events.emit('g-set-war-data', {
      numberOfMachines,
      gangwarMinNumberOfMachines: activeSeason?.warConfig?.gangwarMinNumberOfMachines,
      twitterVerified,
    });
  };

  const exportWalletRef = useRef();
  exportWalletRef.current = async () => {
    if (!isAuthenticated || userWallet?.walletClientType !== 'privy') return;
    try {
      await exportWalletPrivy();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const linkTwitterRef = useRef();
  linkTwitterRef.current = async () => {
    try {
      const res0 = await getXCsrfToken();
      localStorage.setItem('CSRF_TOKEN', res0.data.csrfToken);
      const res = await getOauthRequestToken();
      const { oauth_token } = res.data;
      const url = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
      window.location.href = url;
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
    gameRef.current?.events.emit('g-twitter-done');
  };

  const unlinkTwitterRef = useRef();
  unlinkTwitterRef.current = async () => {
    try {
      await unlinkTwitter();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
    gameRef.current?.events.emit('g-twitter-done');
  };

  const updateProfileRef = useRef();
  updateProfileRef.current = () => {
    gameRef.current.events.emit('g-set-profile', {
      username,
      address,
      socials,
      avatarURL: avatarURL_big ?? avatarURL,
    });
  };

  const updateAppVersionRef = useRef();
  updateAppVersionRef.current = () => {
    gameRef.current.events.emit('g-set-app-version', appVersion);
  };

  const swapRef = useRef();
  swapRef.current = async ({ tokenSwap, amount }) => {
    try {
      gameRef.current.events.emit('g-swap-started', { amount, txnHash: '' });
      const func = tokenSwap === 'eth' ? swapEthToToken : swapTokenToEth;
      const { receipt, receiveAmount } = await func(amount);
      if (receipt.status === 1) {
        gameRef.current.events.emit('g-swap-completed', {
          txnHash: receipt.transactionHash,
          amount: receiveAmount,
          token: tokenSwap === 'eth' ? '$GOLD' : 'ETH',
          description: tokenSwap === 'eth' ? 'Swap ETH to $GOLD completed' : 'Swap $GOLD to ETH completed',
        });
        reloadBalance();
      }
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-swap-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const convertEthInputToTokenRef = useRef();
  convertEthInputToTokenRef.current = async ({ amount }) => {
    try {
      const res = await convertEthInputToToken(amount);
      gameRef.current?.events.emit('g-convert-eth-input-to-token-result', {
        amount: res.amount,
      });
    } catch (err) {
      gameRef.current?.events.emit('g-swap-error');
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const convertTokenInputToEthRef = useRef();
  convertTokenInputToEthRef.current = async ({ amount }) => {
    try {
      const res = await convertTokenInputToEth(amount);
      gameRef.current?.events.emit('g-convert-token-input-to-eth-result', {
        amount: res.amount,
      });
    } catch (err) {
      gameRef.current?.events.emit('g-swap-error');
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const getGasSwapRef = useRef();
  getGasSwapRef.current = () => {
    gameRef.current.events.emit('g-set-gas-swap', { gas: estimatedGas?.swap?.swapEthToToken });
  };

  const applyInviteCodeRef = useRef();
  applyInviteCodeRef.current = async ({ code }) => {
    try {
      const res = await applyInviteCode({ code });
      const { referrerProgramType } = res.data;

      gameRef.current.events.emit('g-complete-apply-invite-code', {
        status: 'Success',
        message: 'Success',
        referrerProgramType,
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      gameRef.current.events.emit('g-complete-apply-invite-code', { status: 'Error', message: err.message });
    }
  };

  const updateInviteCodeRef = useRef();
  updateInviteCodeRef.current = () => {
    if (inviteCode) gameRef.current.events.emit('g-set-invite-code', { code: inviteCode });
  };

  const updateReferalConfigRef = useRef();
  updateReferalConfigRef.current = () => {
    gameRef.current.events.emit('g-set-referral-config', activeSeason?.referralConfig);
  };

  const updateReferralCodeRef = useRef();
  updateReferralCodeRef.current = () => {
    gameRef.current?.events.emit('g-set-referral-code', { referralCode });
  };

  const updateReferralDataRef = useRef();
  updateReferralDataRef.current = () => {
    gameRef.current.events.emit('g-set-referral-data', {
      referralTotalReward,
      referralTotalDiscount,
      referralProgramType,
      tokenPrice,
      ethPriceInUsd,
      tweetTemplate: templates.twitterShareReferralCode || '',
    });
  };

  const updateStatisticRef = useRef();
  updateStatisticRef.current = async () => {
    try {
      const res = await getRank();
      const { rank, totalPlayers } = res.data;

      const { networth, networthPerDay } = calculateNetworth();

      gameRef.current?.events.emit('g-set-statistic', {
        rank,
        totalPlayers,
        numberOfWorkers,
        numberOfMachines,
        numberOfBuildings,
        numberOfThugs,
        raidPoint,
        numberOfPistols,
        numberOfShields,
        networth,
        networthPerDay,
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateUserToAttackDetailRef = useRef();
  updateUserToAttackDetailRef.current = async ({ userId }) => {
    try {
      const res = await getUserDetailToAttack(userId);
      const { user, gamePlay, warResults, rivalry, nextBonusDefencePercent } = res.data;
      gameRef.current?.events.emit('g-set-user-to-attack-detail', {
        user,
        gamePlay,
        warResults,
        rivalry,
        nextBonusDefencePercent,
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateWarConfigRef = useRef();
  updateWarConfigRef.current = () => {
    const { tokenRewardPerEarner, earningStealPercent, machinePercentLost, stolenNetworthPercent, sizeAdjustment } =
      activeSeason.warConfig;
    gameRef.current?.events.emit('g-set-war-config', {
      tokenRewardPerEarner,
      earningStealPercent,
      machinePercentLost,
      stolenNetworthPercent,
      sizeAdjustment,
    });
  };

  const updateNextWarTimeRef = useRef();
  updateNextWarTimeRef.current = async () => {
    try {
      const res = await getNextWarSnapshotUnixTime();
      gameRef.current.events.emit('g-set-next-war-time', { time: res.data.time });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateWarHistoryRef = useRef();
  updateWarHistoryRef.current = async () => {
    try {
      const res = await getWarHistory();
      gameRef.current.events.emit('g-set-war-history', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateWarHistoryDetailRef = useRef();
  updateWarHistoryDetailRef.current = async ({ warSnapshotId, warResultId }) => {
    try {
      const res = await getWarHistoryDetail({ warSnapshotId, warResultId });
      gameRef.current?.events.emit('g-set-war-history-detail', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateUserAugmentsRef = useRef();
  updateUserAugmentsRef.current = async () => {
    try {
      const res = await getUserAugments();
      gameRef.current?.events.emit('g-set-user-augments', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const selectAugmentRef = useRef();
  selectAugmentRef.current = async ({ augment }) => {
    try {
      await selectAugment({ augmentId: augment.id });
      gameRef.current?.events.emit('g-select-augment-completed', {
        augment,
      });
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-select-augment-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const rollAugmentsRef = useRef();
  rollAugmentsRef.current = async (data) => {
    try {
      const txnHash = await rollAugments(data);
      await updateUserAugmentsRef.current();
      gameRef.current?.events.emit('g-roll-augments-completed', { txnHash });
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-roll-augments-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const updateUserRewardRef = useRef();
  updateUserRewardRef.current = async () => {
    try {
      const res = await getUserRewards();
      gameRef.current.events.emit('g-set-user-rewards', { ...res.data, template: templates.twitterShareReward });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const linkWagmiWalletRef = useRef();
  linkWagmiWalletRef.current = () => {
    const connector = connectors.find((item) => item.name === 'MetaMask');
    disconnect(
      { connector },
      {
        onSuccess: () => {
          connect(
            { connector },
            {
              onSuccess: async (data) => {
                const account = data.accounts[0];
                if (!account) throw new Error('No address');
                gameRef.current.events.emit('g-signing-wagmi-wallet');
                try {
                  await signMessage({ account: account.toLowerCase() });
                  loadWalletAirdropRef.current?.();
                  disconnect({ connector });
                  gameRef.current.events.emit('g-link-wagmi-wallet-success');
                } catch (err) {
                  console.error(err.message);
                  gameRef.current.events.emit('g-link-wagmi-wallet-error', { message: err?.message || null });
                }
              },
              onError: async (err) => {
                console.error(err);
                let message = 'Something is wrong';
                if (err.message.includes("Request of type 'wallet_requestPermissions' already pending")) {
                  message = 'Please close Metamask popup and try again';
                }
                gameRef.current.events.emit('g-link-wagmi-wallet-error', { message });
              },
            }
          );
        },
      }
    );
  };

  const loadWalletAirdropRef = useRef();
  loadWalletAirdropRef.current = async () => {
    try {
      const res = await getAirdropWalletList();
      gameRef.current?.events.emit('g-set-airdrop-data', {
        data: res.data,
        max: activeSeason?.airdropConfig?.maxAirdropThugs,
        numberOfClaimedAirdropThugs: gamePlay?.numberOfClaimedAirdropThugs,
        status: activeSeason?.status,
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateGoonPriceRef = useRef();
  updateGoonPriceRef.current = async ({ timeMode }) => {
    try {
      const res = await getWorkerPrices({ timeMode });
      gameRef.current?.events.emit('g-set-goon-price', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateWorkerRef = useRef();
  updateWorkerRef.current = () => {
    if (!activeSeason) return;
    const now = Date.now();
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const roundedMinutes = Math.ceil(minutes);
    const roundedDays = roundedMinutes / (24 * 60);
    const fakePurchases = purchaseConfig[`${roundedMinutes}`] || purchaseConfig['default'];

    gameRef.current?.events.emit('g-set-workers', {
      numberOfWorkers,
      balance: tokenBalance,
      basePrice: worker.basePrice,
      targetDailyPurchase: worker.targetDailyPurchase,
      pricePower: worker.pricePower,
      targetPrice: worker.targetPrice,
      dailyWarCount: 24 / (activeSeason?.warConfig?.warIntervalDurationInHours || 24),
      maxPerBatch: worker.maxPerBatch,
      totalSold: activeSeason?.workerSold + fakePurchases.numberOfWorkerFakePurchases,
      days: roundedDays,
      dailyReward: worker.dailyReward,
      networthBase: worker.networthBase,
      networthPerDay: worker.networthPerDay,
      isTrackingSales: listeningWorker,
      started: now > activeSeason.startTime.toDate().getTime(),
      startingPrice: worker?.startingPrice,
    });
  };

  const updateHousePriceRef = useRef();
  updateHousePriceRef.current = async ({ timeMode }) => {
    try {
      const res = await getBuildingPrices({ timeMode });
      gameRef.current?.events.emit('g-set-house-price', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
    getBuildingPrices({ timeMode })
      .then((res) => gameRef.current?.events.emit('update-house-price', res.data))
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  };

  const updateBuildingRef = useRef();
  updateBuildingRef.current = () => {
    if (!activeSeason) return;
    const now = Date.now();
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const roundedMinutes = Math.ceil(minutes);
    const roundedDays = roundedMinutes / (24 * 60);
    const fakePurchases = purchaseConfig[`${roundedMinutes}`] || purchaseConfig['default'];

    gameRef.current?.events.emit('g-set-buildings', {
      numberOfBuildings,
      basePrice: building.basePrice,
      maxPerBatch: building.maxPerBatch,
      targetDailyPurchase: building.targetDailyPurchase,
      pricePower: building.pricePower,
      targetPrice: building.targetPrice,
      totalSold: activeSeason?.buildingSold + fakePurchases.numberOfBuildingFakePurchases,
      days: roundedDays,
      networthBase: building.networthBase,
      networthPerDay: building.networthPerDay,
      isTrackingSales: listeningBuilding,
      started: now > activeSeason.startTime.toDate().getTime(),
      startingPrice: building?.startingPrice,
    });
  };

  const updateGangsterPriceRef = useRef();
  updateGangsterPriceRef.current = async ({ timeMode }) => {
    try {
      const res = await getMachinePrices({ timeMode });
      gameRef.current?.events.emit('g-set-gangster-price', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateMachineRef = useRef();
  updateMachineRef.current = () => {
    if (!activeSeason) return;
    const now = Date.now();
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const roundedMinutes = Math.ceil(minutes);
    const roundedDays = roundedMinutes / (24 * 60);
    const fakePurchases = purchaseConfig[`${roundedMinutes}`] || purchaseConfig['default'];

    console.log({
      roundedMinutes,
      roundedDays,
      sold: activeSeason?.machineSold,
      fake: fakePurchases.numberOfMachineFakePurchases,
    });

    gameRef.current?.events.emit('g-set-machines', {
      numberOfMachines,
      balance: tokenBalance,
      maxPerBatch: machine.maxPerBatch,
      dailyReward: gamePlay?.machine?.dailyReward || machine.dailyReward,
      networthBase: machine.networthBase,
      networthPerDay: machine.networthPerDay,
      isWhitelisted,
      whitelistAmountLeft: machine.maxWhitelistAmount - whitelistAmountMinted,
      basePrice: machine.basePrice,
      basePriceWhitelist: machine.whitelistPrice,
      targetDailyPurchase: machine.targetDailyPurchase,
      pricePower: machine.pricePower,
      targetPrice: machine.targetPrice,
      totalSold: activeSeason?.machineSold + fakePurchases.numberOfMachineFakePurchases,
      days: roundedDays,
      isTrackingSales: listeningMachine,
      started: now > activeSeason.startTime.toDate().getTime(),
      startingPrice: machine?.startingPrice,
    });
  };

  const updatePrizePoolInfoRef = useRef();
  updatePrizePoolInfoRef.current = () => {
    gameRef.current?.events.emit('g-set-prize-pool-info', {
      prizePoolConfig,
      xUPool: xUReward,
      blastGoldReward,
    });
  };

  const updateUserListToAttackRef = useRef();
  updateUserListToAttackRef.current = async ({ page, limit, search, orderBy, sortDirection }) => {
    try {
      const res = await getUserListToAttack({ page, limit, search, orderBy, sortDirection });
      const { totalDocs, docs } = res.data;
      const totalPages = Math.ceil(totalDocs / limit);
      gameRef.current?.events.emit('g-set-user-list-to-attack', { totalPages, users: docs });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      gameRef.current?.events.emit('g-set-user-list-to-attack-failed');
    }
  };

  const updateAuthRef = useRef();
  updateAuthRef.current = () => {
    gameRef.current?.events.emit('g-set-auth', { uid: profile.id });
  };

  const updateSpinRewardRef = useRef();
  updateSpinRewardRef.current = () => {
    if (!activeSeason || !gamePlay?.startNetworthCountingTime)
      return gameRef.current?.events.emit('g-set-spin-rewards', {
        spinRewards: JSON.parse(JSON.stringify(spinRewards))
          .sort((item1, item2) => item1.order - item2.order)
          .map((item) => {
            if (item.type === 'GREED')
              return {
                ...item,
                value: Math.floor(item.value * (1 + tokenReputationRewardMutiplier * Math.floor(networth))),
              };
            return item;
          }),
        spinPrice: calculateSpinPrice(Math.floor(networth), costReputationMultiplier, basePrice),
      });

    const now = Date.now();
    const { startNetworthCountingTime } = gamePlay;
    const networthPerDay =
      numberOfMachines * machine.networthPerDay +
      numberOfWorkers * worker.networthPerDay +
      numberOfBuildings * building.networthPerDay;
    const networthDiffInDays = (now - startNetworthCountingTime.toDate().getTime()) / MILISECONDS_IN_A_DAY;
    const generatedNetworth = networthPerDay * networthDiffInDays;
    const calculatedNetworth = Math.floor(networth + generatedNetworth);

    gameRef.current?.events.emit('g-set-spin-rewards', {
      spinRewards: JSON.parse(JSON.stringify(spinRewards))
        .sort((item1, item2) => item1.order - item2.order)
        .map((item) => {
          if (item.type === 'GREED')
            return {
              ...item,
              value: Math.floor(item.value * (1 + tokenReputationRewardMutiplier * calculatedNetworth)),
            };
          return item;
        }),
      spinPrice: calculateSpinPrice(calculatedNetworth, costReputationMultiplier, basePrice),
    });
  };

  const startSpinRef = useRef();
  startSpinRef.current = async () => {
    try {
      await initDailySpin();
    } catch (err) {
      if (err.message === 'Already spin today') {
        gameRef.current?.events.emit('g-spin-error', {
          code: '4001',
          message: err.message,
        });
      } else {
        const { message, code } = handleError(err);
        gameRef.current?.events.emit('g-spin-error', {
          code,
          message,
        });
      }
    }
  };

  const updateSpinHistoryRef = useRef();
  updateSpinHistoryRef.current = async ({ page, limit }) => {
    try {
      const res = await getSpinTxns({ page, limit });
      const { totalPages, items } = res.data;
      gameRef.current?.events.emit('g-set-spin-history', {
        totalPages,
        txns: items.map((item) => ({
          createdAt: moment(new Date(item.createdAt)).format('DD/MM, HH:mm'),
          reward: item.reward,
        })),
      });
    } catch (err) {
      gameRef.current?.events.emit('g-set-spin-history-error');
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const loadStarterPackAvailableRef = useRef();
  loadStarterPackAvailableRef.current = async ({ packId }) => {
    if (!activeSeason?.starterPackConfig?.[packId]) {
      gameRef.current.events.emit('g-set-starter-pack-available', {
        starterPackAvailable: false,
      });
      return;
    }

    const now = Date.now();
    const packEndTime = activeSeason?.starterPackConfig[packId]?.availableUntil?.toDate()?.getTime();
    gameRef.current.events.emit('g-set-starter-pack-available', {
      starterPackAvailable:
        activeSeason?.starterPackConfig[packId].status === 'active' &&
        packsPurchased[packId] < activeSeason.starterPackConfig[packId].maxPerUser &&
        packEndTime &&
        now < packEndTime,
    });
  };

  const updateTwitterShareThugAirdropTemplateRef = useRef();
  updateTwitterShareThugAirdropTemplateRef.current = () => {
    gameRef.current?.events.emit('g-set-twitter-share-thug-airdrop-template', {
      template: templates.twitterShareThugAirdrop,
    });
  };

  const claimThugAirdropRef = useRef();
  claimThugAirdropRef.current = async ({ shared }) => {
    try {
      await claimThug({ shared });
      await loadWalletAirdropRef.current?.();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
    gameRef.current?.events.emit('g-claim-thug-airdrop-done');
  };

  const updateTwitterShareFreeThugsTemplateRef = useRef();
  updateTwitterShareFreeThugsTemplateRef.current = () => {
    gameRef.current?.events.emit('g-set-twitter-share-free-thugs-template', {
      template: templates.twitterShareFreeThug,
    });
  };

  const updateNumberOfFreeThugRef = useRef();
  updateNumberOfFreeThugRef.current = () => {
    gameRef.current?.events.emit('g-set-number-of-free-thugs', {
      numberOfFreeThugs: activeSeason?.airdropConfig?.numberOfFreeThugs || 0,
    });
  };

  const claimFreeThugRef = useRef();
  claimFreeThugRef.current = async ({ shared }) => {
    try {
      await claimFreeThug({ shared });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
    gameRef.current?.events.emit('g-claim-free-thugs-done');
  };

  const buyStarterPackRef = useRef();
  buyStarterPackRef.current = async ({ packId, goonsQuantity, gangstersQuantity, thugsQuantity }) => {
    try {
      const txnHash = await buyStarterPack({ packId });
      gameRef.current?.events.emit('g-buy-starter-pack-completed', {
        txnHash,
        goonsQuantity,
        gangstersQuantity,
        thugsQuantity,
      });
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-buy-starter-pack-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const updateStarterPackConfigRef = useRef();
  updateStarterPackConfigRef.current = () => {
    gameRef.current.events.emit('g-set-starter-pack-config', activeSeason?.starterPackConfig);
  };

  const updateAugmentConfigsRef = useRef();
  updateAugmentConfigsRef.current = () => {
    if (augmentConfigs) gameRef.current.events.emit('g-set-augment-configs', augmentConfigs);
  };

  const updateMarketDataRef = useRef();
  updateMarketDataRef.current = () => {
    gameRef.current?.events.emit('g-set-market-data', { tokenPrice, ethPriceInUsd });
  };

  const updatePortfolioRef = useRef();
  updatePortfolioRef.current = () => {
    const machineValue = numberOfMachines * parseFloat(nftPrice);
    gameRef.current?.events?.emit('g-set-portfolio', {
      address,
      ETHBalance,
      tokenBalance,
      tokenPrice,
      numberOfMachines,
      machineValue,
      blastPointReward,
    });
  };

  const updateLeaderboardRef = useRef();
  updateLeaderboardRef.current = async ({ pages, limit, isSimulator }) => {
    if (isSimulator) {
      gameRef?.current?.events.emit('g-set-leaderboard-list', simulator.leaderboard);
      return;
    }

    try {
      const [reputationRes, raidPointRes, uPointRes] = await Promise.all([
        getLeaderboard({ page: pages.reputation, limit }),
        getRaidPointLeaderboard({ page: pages.raidPoints, limit }),
        getUPointLeaderboard({ page: pages.uPoints, limit }),
      ]);

      gameRef?.current?.events.emit('g-set-leaderboard-list', {
        totalPages: {
          reputation: reputationRes.data.totalPages,
          raidPoints: raidPointRes.data.totalPages,
          uPoints: uPointRes.data.totalPages,
        },
        users: {
          reputation: reputationRes.data.users,
          raidPoints: raidPointRes.data.users,
          uPoints: uPointRes.data.users,
        },
        userRecord: {
          reputation: reputationRes.data.userRecord,
          raidPoints: raidPointRes.data.userRecord,
          uPoints: uPointRes.data.userRecord,
        },
      });
      gameRef?.current?.events.emit('g-set-portfolio-rewards', {
        rankEthReward: reputationRes.data.userRecord.ethReward + raidPointRes.data.userRecord.ethReward,
        rankTokenReward: reputationRes.data.userRecord.tokenReward + raidPointRes.data.userRecord.tokenReward,
        xU: uPointRes.data.userRecord.xUReward,
        blastGold: uPointRes.data.userRecord.blastGoldReward + (gamePlay?.blastGoldRewardFromAuctionHouse || 0),
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const warUpgradeRef = useRef();
  warUpgradeRef.current = async ({ pistolQuantity, shieldQuantity, token }) => {
    try {
      const txnHash = await buyWarUpgrades({ type: 'pistol', quantity: pistolQuantity, token });
      const txnHash2 = await buyWarUpgrades({ type: 'shield', quantity: shieldQuantity, token });
      gameRef.current?.events.emit('g-war-upgrade-completed', {
        txnHash,
        txnHash2,
        pistolQuantity,
        shieldQuantity,
      });
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-war-upgrade-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const updateGasBuyGoonRef = useRef();
  updateGasBuyGoonRef.current = () => {
    gameRef.current.events.emit('g-set-gas-buy-goon', { gas: estimatedGas?.game?.buyAsset });
  };

  const updateWarUpgradeRef = useRef();
  updateWarUpgradeRef.current = () => {
    if (!gamePlay || !activeSeason) return;

    const {
      machine,
      worker,
      warConfig: { equipmentSpendableIncomeHours },
      pistol,
      shield,
    } = activeSeason;
    const { amountSpentForEquipmentNextWar, numberOfMachines, numberOfWorkers } = gamePlay;

    const totalSpendable =
      ((numberOfMachines * machine.dailyReward + numberOfWorkers * worker.dailyReward) *
        equipmentSpendableIncomeHours) /
      24;
    const spendable = totalSpendable - amountSpentForEquipmentNextWar;

    gameRef.current?.events.emit('g-set-war-upgrades', {
      numberOfPistols,
      numberOfShields,
      pistol,
      shield,
      balance: tokenBalance,
      spendable,
    });
  };

  const updateReferralDiscountRef = useRef();
  updateReferralDiscountRef.current = async () => {
    try {
      const res = await getReferralDiscount();
      gameRef.current.events.emit('g-set-referral-discount', {
        discount: res.data.discount,
        referrerProgramType: res.data.referrerProgramType,
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      gameRef.current.events.emit('g-set-referral-discount', { discount: 0 });
    }
  };

  const upgradeSafehouseRef = useRef();
  upgradeSafehouseRef.current = async ({ quantity, token }) => {
    try {
      const txnHash = await buyBuilding({ quantity, token });
      gameRef.current?.events.emit('g-upgrade-safehouse-completed', { txnHash, amount: quantity });
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-upgrade-safehouse-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const updateGasBuySafeHouseRef = useRef();
  updateGasBuySafeHouseRef.current = () => {
    gameRef.current.events.emit('g-set-gas-upgrade-safehouse', { gas: estimatedGas?.game?.buyAsset });
  };

  const calculateNetworthRef = useRef();
  calculateNetworthRef.current = () => {
    const { networth, networthPerDay } = calculateNetworth();

    gameRef.current?.events.emit('g-set-networth', {
      networth,
      networthPerDay,
    });
  };

  const buyGoonRef = useRef();
  buyGoonRef.current = async ({ quantity, token }) => {
    try {
      const txnHash = await buyWorker({ quantity, token });
      gameRef.current?.events.emit('g-buy-goon-completed', { txnHash, amount: quantity });
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-buy-goon-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const buyThugRef = useRef();
  buyThugRef.current = async ({ amount }) => {
    try {
      await buyThugsWithPoorToken({ amount });
      gameRef.current?.events.emit('g-buy-thug-completed', { amount });
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-buy-thug-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const updateThugRef = useRef();
  updateThugRef.current = () => {
    if (!activeSeason) return;
    const now = Date.now();
    const minutes = (now - activeSeason.startTime.toDate().getTime()) / MILLISECONDS_PER_MINUTE;
    const roundedMinutes = Math.ceil(minutes);
    const roundedDays = roundedMinutes / (24 * 60);
    const fakePurchases = purchaseConfig[`${roundedMinutes}`] || purchaseConfig['default'];

    gameRef.current?.events.emit('g-set-thugs', {
      numberOfThugs,
      basePrice: thug.basePrice,
      maxPerBatch: thug.maxPerBatch,
      targetDailyPurchase: thug.targetDailyPurchase,
      pricePower: thug.pricePower,
      targetPrice: thug.targetPrice,
      totalSold: activeSeason?.thugSold + fakePurchases.numberOfThugFakePurchases,
      days: roundedDays,
      dailyReward: thug.dailyReward,
      networthBase: thug.networthBase,
      networthPerDay: thug.networthPerDay,
      isTrackingSales: listeningThug,
      started: now > activeSeason.startTime.toDate().getTime(),
      startingPrice: thug?.startingPrice,
    });
  };

  const buyGangsterRef = useRef();
  buyGangsterRef.current = async ({ quantity, mintFunction, isSimulator }) => {
    if (isSimulator) {
      await delay(1000);
      gameRef.current?.events.emit('g-buy-gangster-completed', { amount: quantity });
      gameRef.current?.events.emit('g-set-animation-assets', { numberOfMachines: quantity });
      return;
    }
    try {
      const txnHash = await buyGangster(quantity, mintFunction);
      gameRef.current?.events.emit('g-buy-gangster-completed', { txnHash, amount: quantity });
    } catch (err) {
      const { message, code } = handleError(err);
      gameRef.current?.events.emit('g-buy-gangster-completed', {
        status: 'failed',
        code,
        message,
      });
    }
  };

  const updateGasMintRef = useRef();
  updateGasMintRef.current = () => {
    gameRef.current.events.emit('g-set-gas-mint', { gas: estimatedGas?.game?.buyGangster });
  };

  const updateWarMachineRef = useRef();
  updateWarMachineRef.current = async (data) => {
    try {
      await updateUserWarDeployment(data);
      gameRef.current?.events.emit('g-update-war-machines-completed', data);
      await reloadUserWarDeployment();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      gameRef.current?.events.emit('g-update-war-machines-error');
    }
  };

  const updateGamePlayRef = useRef();
  updateGamePlayRef.current = () => {
    gameRef.current?.events.emit('g-set-game-play', {
      numberOfMachines,
      numberOfAvailableMachines,
      numberOfWorkers,
      numberOfBuildings,
      totalPistolBonus: numberOfPistols * pistol.attackPoint,
      totalShieldBonus: numberOfShields * shield.defencePoint,
      ...warDeployment,
      ...activeSeason.warConfig,
      latestWarChain,
    });
  };

  const updateSeasonRef = useRef();
  updateSeasonRef.current = () => {
    const { name } = activeSeason || {};
    gameRef.current.events.emit('g-set-season', {
      name,
      prizePoolEth,
      prizePoolToken,
      blastGoldReward,
      rewardPercentNextPayout: midSeasonSnapshotTaken
        ? 1 - prizePoolConfig.midSeasonPrizePoolPercentage
        : prizePoolConfig.midSeasonPrizePoolPercentage,
      xUPool: xUReward,
      status: activeSeason?.status,
      blastGoldSnapshotTime: activeSeason?.blastGoldSnapshotTime,
      midSeasonSnapshotTime: activeSeason?.midSeasonSnapshotTime,
      midSeasonSnapshotTaken: activeSeason?.midSeasonSnapshotTaken,
      estimatedEndTime: activeSeason?.estimatedEndTime,
      startTime: activeSeason?.startTime,
    });
  };

  const updateRankRef = useRef();
  updateRankRef.current = async ({ isSimulator } = {}) => {
    if (isSimulator) {
      gameRef.current.events.emit('g-set-rank', { rank: 8 });
      return;
    }
    try {
      const res = await getRank();
      gameRef.current.events.emit('g-set-rank', { rank: res.data.rank });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const getRewardRef = useRef();
  getRewardRef.current = async () => {
    try {
      const res = await getReward();
      gameRef.current?.events?.emit('g-set-end-game-reward', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      gameRef.current?.events?.emit('g-set-end-game-reward', {
        ethReward: 0,
        tokenReward: 0,
        blastGoldReward: 0,
        ethDistributed: 0,
        tokenDistributed: 0,
        blastGoldDistributed: 0,
      });
    }
  };

  const getClaimFreeThugRef = useRef();
  getClaimFreeThugRef.current = () => {
    gameRef.current?.events.emit('g-set-claimed-free-thugs', {
      claimedFreeThugs: gamePlay?.claimedFreeThugs,
      seasonStatus: activeSeason?.status,
    });
  };

  const updateUserAwayRewardRef = useRef();
  updateUserAwayRewardRef.current = async () => {
    try {
      if (
        !profile ||
        !gamePlay?.startXTokenCountingTime ||
        (gamePlay && numberOfMachines === 0) ||
        activeSeason?.status !== 'open'
      ) {
        gameRef.current?.events.emit('g-set-user-away-reward', {
          noShow: true,
        });
        return;
      }
      const lastUnixTimeSeenWarResult = gamePlay?.lastTimeSeenWarResult
        ? gamePlay?.lastTimeSeenWarResult.toDate().getTime()
        : 0;
      const res = await getLatestWar();
      const latestWar = res.data?.latestWar;
      const latestWarUnixTime = latestWar?.createdAt;
      const showWarPopup = latestWarUnixTime && lastUnixTimeSeenWarResult < latestWarUnixTime;

      let startTime = gamePlay.startXTokenCountingTime.toDate().getTime();
      if (profile.lastOnlineTime) {
        startTime = profile.lastOnlineTime.toDate().getTime();
      }

      const now = Date.now();
      const diffInDays = (now - startTime) / MILISECONDS_IN_A_DAY;
      const claimableReward = Math.abs(diffInDays * dailyXToken);

      const networthPerDay =
        numberOfMachines * machine.networthPerDay +
        numberOfBuildings * building.networthPerDay +
        numberOfWorkers * worker.networthPerDay;
      const generatedNetworth = Math.floor(diffInDays * networthPerDay);
      gameRef.current?.events.emit('g-set-user-away-reward', {
        showWarPopup,
        claimableReward,
        generatedNetworth,
      });
      setOnlineListener(true);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateClaimableStatusRef = useRef();
  updateClaimableStatusRef.current = () => {
    const now = Date.now();
    const nextClaimTime = gamePlay.lastClaimTime.toDate().getTime() + activeSeason.claimGapInSeconds * 1000;
    const claimable = now > nextClaimTime && activeSeason.status === 'open';
    gameRef.current.events.emit('g-set-claimable-status', { claimable });
  };

  const updateClaimTimeRef = useRef();
  updateClaimTimeRef.current = () => {
    if (!gamePlay?.lastClaimTime || !activeSeason?.claimGapInSeconds) return;
    gameRef.current.events.emit('g-set-claim-time', {
      claimGapInSeconds: activeSeason.claimGapInSeconds,
      lastClaimTime: gamePlay.lastClaimTime.toDate().getTime(),
    });
  };

  const updateWalletNFTBalanceRef = useRef();
  updateWalletNFTBalanceRef.current = async () => {
    try {
      const balance = await getNFTBalance(address);
      gameRef.current.events.emit('g-set-wallet-nft-balance', { balance, numberOfMachines });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateWalletNFTUnstakedRef = useRef();
  updateWalletNFTUnstakedRef.current = async () => {
    try {
      const balance = await getNFTBalance(address);
      gameRef.current.events.emit('g-set-wallet-nft-unstaked', { balance });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateLastTimeSeenWarRef = useRef();
  updateLastTimeSeenWarRef.current = async () => {
    try {
      await updateLastTimeSeenGangWarResult();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateWarHistoryLatestRef = useRef();
  updateWarHistoryLatestRef.current = async () => {
    try {
      const res = await getLatestWarResult();
      gameRef.current?.events.emit('g-set-war-history-latest', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateBalanceForWithdrawRef = useRef();
  updateBalanceForWithdrawRef.current = () => {
    gameRef.current.events.emit('g-set-balances-for-withdraw', {
      NFTBalance: numberOfMachines,
      ETHBalance,
      tokenBalance,
    });
  };

  const checkUserCompletedTutorialRef = useRef();
  checkUserCompletedTutorialRef.current = () => {
    const completed = profile.completedTutorial;
    gameRef.current?.events.emit('g-set-user-completed-tutorial', { completed });
  };

  const updateAuctionItemRef = useRef();
  updateAuctionItemRef.current = async () => {
    try {
      const res = await getAuctionItems();
      gameRef.current.events.emit('g-set-auction-items', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const createBiddingRef = useRef();
  createBiddingRef.current = async (data) => {
    try {
      await createBidding(data);
      gameRef.current.events.emit('g-create-bidding-success');
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      gameRef.current.events.emit('g-create-bidding-fail', err.message);
    }
  };

  const updateBiddingHistoryRef = useRef();
  updateBiddingHistoryRef.current = async () => {
    try {
      const res = await getBiddingHistory();
      gameRef.current.events.emit('g-set-bidding-history', res.data);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateAuctionItemBiddingHistoryRef = useRef();
  updateAuctionItemBiddingHistoryRef.current = async ({ subId }) => {
    try {
      const cachedData = cachedRef.current.auctionItemHistory?.[subId];
      let refetch = !cachedData;
      if (cachedData) {
        const now = Date.now();
        const diff = now - cachedData.time;
        if (diff > cacheTime) {
          refetch = true;
        }
      }
      if (refetch) {
        const res = await getAuctionItemHistory({ subId });
        cachedRef.current.auctionItemHistory[subId] = { data: res.data, time: Date.now() };
        gameRef.current.events.emit('g-set-auction-item-bidding-history', res.data);
      } else {
        gameRef.current.events.emit('g-set-auction-item-bidding-history', cachedData.data);
      }
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateAuctionItemBiddingHistoryDetailRef = useRef();
  updateAuctionItemBiddingHistoryDetailRef.current = async ({ subId, recordId }) => {
    try {
      const cachedData = cachedRef.current.auctionItemHistoryDetail[subId]?.[recordId];
      let refetch = !cachedData;
      if (cachedData) {
        const now = Date.now();
        const diff = now - cachedData.time;
        if (diff > cacheTime) {
          refetch = true;
        }
      }
      if (refetch) {
        const res = await getAuctionItemHistoryDetail({ subId, recordId });
        cachedRef.current.auctionItemHistoryDetail[subId] = { [recordId]: { data: res.data, time: Date.now() } };
        gameRef.current.events.emit('g-set-auction-item-bidding-history-detail', res.data);
      } else {
        gameRef.current.events.emit('g-set-auction-item-bidding-history-detail', cachedData.data);
      }
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateAuctionEndTimeRef = useRef();
  updateAuctionEndTimeRef.current = async () => {
    try {
      const res = await getAuctionEndTime();
      gameRef.current.events.emit('g-set-auction-end-time', res.data.endTimeUnix);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateNextAuctionPrizeRef = useRef();
  updateNextAuctionPrizeRef.current = () => {
    gameRef.current.events.emit('g-set-next-auction-prize', { nextAuctionPrizePool });
  };

  const endTutorialRef = useRef();
  endTutorialRef.current = async () => {
    try {
      await completeTutorial();
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  const updateClaimCoolDownRef = useRef();
  updateClaimCoolDownRef.current = async () => {
    gameRef.current.events.emit('g-set-claim-cool-down', { claimGapInSeconds: activeSeason.claimGapInSeconds });
  };

  const updateDepositAddressRef = useRef();
  updateDepositAddressRef.current = () => {
    gameRef.current.events.emit('g-set-deposit-address', { depositAddress: profile?.depositAddress });
  };

  const openMoonPayRef = useRef();
  openMoonPayRef.current = () => {
    setOpenMoonPay(true);
  };

  const fundWalletRef = useRef();
  fundWalletRef.current = async ({ value }) => {
    await fundWallet(profile?.address, { chain, amount: value });
  };

  const updateWarUpgradeExplainRef = useRef();
  updateWarUpgradeExplainRef.current = () => {
    if (!gamePlay || !activeSeason) return;

    const {
      machine,
      worker,
      warConfig: { equipmentSpendableIncomeHours },
    } = activeSeason;
    const { numberOfMachines, numberOfWorkers, amountSpentForEquipmentNextWar } = gamePlay;

    const maxSpendable =
      ((numberOfMachines * machine.dailyReward + numberOfWorkers * worker.dailyReward) *
        equipmentSpendableIncomeHours) /
      24;

    gameRef.current.events.emit('g-set-war-upgrade-explain', {
      equipmentSpendableIncomeHours,
      maxSpendable,
      amountSpentForEquipmentNextWar,
    });
  };

  useEffect(() => {
    if (profile && gamePlay && activeSeasonId && !loaded && !!userWallet) {
      setLoaded(true);
    }
  }, [loaded, profile, gamePlay, activeSeasonId, userWallet]);

  useEffect(() => {
    if (!gameLoaded.current) {
      gameLoaded.current = true;

      const config = {
        type: Phaser.CANVAS,
        width,
        height,
        pixelArt: true,
        transparent: true,
        parent: 'game-container',
        gameVersion: GAME_VERSION,
        fps: {
          target: 60,
        },
        scale: {
          mode: screenRatio > 0.9 ? Phaser.Scale.FIT : Phaser.Scale.NO_SCALE,
        },
        scene: [LoadingScene, TutorialScene, MainScene, AuctionScene],
        debug: false,
        audio: {
          mute: sound !== 'on',
        },
        dom: {
          createContainer: true,
        },
        plugins: {
          global: [
            {
              key: 'rexCircleMaskImagePlugin',
              plugin: CircleMaskImagePlugin,
              start: true,
            },
          ],
        },
      };

      const game = new Phaser.Game(config);
      game.sound.setMute(sound !== 'on');

      game.events.on('g-hide-bg', () => {
        logAnalyticsEvent('game_load', { loading_duration: (Date.now() - startLoadingTime) / 1000 });
        setShowBg(false);
      });

      gameRef.current = game;
    }

    if (loaded && !gameEventListened.current) {
      gameEventListened.current = true;

      // listeners
      gameRef.current?.events.on('g-get-season-status', () => {
        onSeasonStatusChanged.current?.();
      });

      gameRef.current?.events.on('g-get-animation-assets', () => updateAnimationAssetsRef.current?.());

      gameRef.current?.events.on('g-get-poor-token-balance', () => updatePoorTokenBalanceRef.current?.());

      gameRef.current?.events.on('g-get-claimable-reward', () => calculateClaimableRewardRef.current?.());

      gameRef.current?.events.on('g-get-networth-house', (data) => calculateNetworthHouseRef?.current?.(data));

      gameRef.current?.events.on('g-get-deposit-code', () => updateDepositCodeRef.current?.());

      gameRef.current?.events.on('g-get-eth-balance', () => updateETHBalanceRef.current?.());

      gameRef.current?.events.on('g-get-balances', () => updateBalanceRef.current?.());

      gameRef.current?.events.on('g-get-x-token-balance', () => updateXTokenBalanceRef.current?.());

      gameRef.current?.events.on('g-claim', (data) => claimRef.current?.(data));

      gameRef.current?.events.on('g-get-war-data', () => getWarDataRef.current?.());

      gameRef.current?.events.on('g-export-wallet', () => exportWalletRef.current?.());

      gameRef.current?.events.on('g-log-out', logout);

      gameRef.current.events?.on('g-link-twitter', () => linkTwitterRef.current?.());

      gameRef.current?.events.on('g-unlink-twitter', () => unlinkTwitterRef.current?.());

      gameRef.current?.events.on('g-toggle-game-sound', toggleSound);

      gameRef.current?.events.on('g-get-profile', () => updateProfileRef.current?.());

      gameRef.current?.events.on('g-get-app-version', () => updateAppVersionRef.current?.());

      gameRef.current?.events.on('g-swap', ({ tokenSwap, amount }) => swapRef.current?.({ tokenSwap, amount }));

      gameRef.current?.events.on('g-convert-eth-input-to-token', ({ amount }) =>
        convertEthInputToTokenRef.current?.({ amount })
      );

      gameRef.current?.events.on('g-convert-token-input-to-eth', ({ amount }) =>
        convertTokenInputToEthRef.current?.({ amount })
      );

      gameRef.current?.events.on('g-get-gas-swap', () => getGasSwapRef.current?.());

      gameRef.current?.events.on('g-apply-invite-code', ({ code }) => applyInviteCodeRef.current?.({ code }));

      gameRef.current?.events.on('g-get-invite-code', () => updateInviteCodeRef.current?.());

      gameRef.current?.events.on('g-get-referral-config', () => updateReferalConfigRef.current?.());

      gameRef.current?.events.on('g-get-referral-code', () => updateReferralCodeRef.current?.());

      gameRef.current?.events.on('g-get-referral-data', () => updateReferralDataRef.current?.());

      gameRef.current?.events.on('g-get-statistic', () => updateStatisticRef.current?.());

      gameRef.current?.events.on('g-get-user-to-attack-detail', ({ userId }) =>
        updateUserToAttackDetailRef.current?.({ userId })
      );

      gameRef.current?.events.on('g-get-war-config', () => updateWarConfigRef.current?.());

      gameRef.current?.events.on('g-get-next-war-time', () => updateNextWarTimeRef.current?.());

      gameRef.current?.events.on('g-get-war-history', () => updateWarHistoryRef.current?.());

      gameRef.current?.events.on('g-get-war-history-detail', ({ warSnapshotId, warResultId }) =>
        updateWarHistoryDetailRef.current?.({ warSnapshotId, warResultId })
      );
      gameRef.current?.events.on('g-get-augment-configs', () => updateAugmentConfigsRef.current?.());

      gameRef.current?.events.on('g-get-user-augments', () => updateUserAugmentsRef.current?.());

      gameRef.current?.events.on('g-select-augment', (data) => selectAugmentRef.current?.(data));

      gameRef.current?.events.on('g-roll-augments', (data) => rollAugmentsRef.current?.(data));

      gameRef.current.events.on('g-get-user-rewards', () => updateUserRewardRef.current?.());

      gameRef.current.events.on('g-link-wagmi-wallet', () => linkWagmiWalletRef.current?.());

      gameRef.current?.events.on('g-get-airdrop-data', () => loadWalletAirdropRef.current?.());

      gameRef.current?.events.on('g-get-goon-price', ({ timeMode }) => updateGoonPriceRef.current?.({ timeMode }));

      gameRef.current?.events.on('g-get-house-price', ({ timeMode }) => updateHousePriceRef.current?.({ timeMode }));

      gameRef.current?.events.on('g-get-gangster-price', ({ timeMode }) =>
        updateGangsterPriceRef.current?.({ timeMode })
      );

      gameRef.current?.events.on('g-get-prize-pool-info', () => updatePrizePoolInfoRef.current?.());

      gameRef.current?.events.on('g-get-user-list-to-attack', ({ page, limit, search, orderBy, sortDirection }) =>
        updateUserListToAttackRef.current?.({ page, limit, search, orderBy, sortDirection })
      );

      gameRef.current?.events.on('g-get-auth', () => updateAuthRef.current?.());

      gameRef.current?.events.on('g-get-spin-rewards', () => updateSpinRewardRef.current?.());

      gameRef.current?.events.on('g-start-spin', () => startSpinRef.current?.());

      gameRef.current?.events.on('g-get-spin-history', ({ page, limit }) =>
        updateSpinHistoryRef.current?.({ page, limit })
      );

      gameRef.current?.events.on('g-get-starter-pack-available', ({ packId }) =>
        loadStarterPackAvailableRef.current?.({ packId })
      );

      gameRef.current?.events.on('g-get-twitter-share-thug-airdrop-template', () =>
        updateTwitterShareThugAirdropTemplateRef.current?.()
      );

      gameRef.current?.events.on('g-claim-thug-airdrop', ({ shared }) => claimThugAirdropRef.current?.({ shared }));

      gameRef.current?.events.on('g-get-twitter-share-free-thugs-template', () =>
        updateTwitterShareFreeThugsTemplateRef.current?.()
      );

      gameRef.current?.events.on('g-get-number-of-free-thugs', () => updateNumberOfFreeThugRef.current?.());

      gameRef.current?.events.on('g-claim-free-thugs', ({ shared }) => claimFreeThugRef.current?.({ shared }));

      gameRef.current?.events.on('g-buy-starter-pack', (data) => buyStarterPackRef.current?.(data));

      gameRef.current?.events.on('g-get-starter-pack-config', () => updateStarterPackConfigRef.current?.());

      gameRef.current?.events.on('g-get-market-data', () => updateMarketDataRef.current?.());

      gameRef.current?.events.on('g-enable-machine-sales-tracking', () => enableMachineSalesTracking());

      gameRef.current?.events.on('g-enable-worker-sales-tracking', () => enableWorkerSalesTracking());

      gameRef.current?.events.on('g-disable-machine-sales-tracking', () => disableMachineSalesTracking());

      gameRef.current?.events.on('g-disable-worker-sales-tracking', () => disableWorkerSalesTracking());

      gameRef.current?.events.on('g-get-portfolio', () => updatePortfolioRef.current?.());

      gameRef.current?.events.on('g-get-leaderboard-list', (data) => updateLeaderboardRef.current?.(data));

      gameRef.current?.events.on('g-war-upgrade-start', (data) => warUpgradeRef.current?.(data));

      gameRef.current?.events.on('g-get-gas-buy-goon', () => updateGasBuyGoonRef.current?.());

      gameRef.current?.events.on('g-get-war-upgrades', () => updateWarUpgradeRef.current?.());

      gameRef.current?.events.on('g-get-referral-discount', () => updateReferralDiscountRef.current?.());

      gameRef.current?.events.on('g-upgrade-safehouse', (data) => upgradeSafehouseRef.current?.(data));

      gameRef.current?.events.on('g-enable-building-sales-tracking', () => {
        enableBuildingSalesTracking();
      });

      gameRef.current?.events.on('g-disable-building-sales-tracking', () => {
        disableBuildingSalesTracking();
      });

      gameRef.current?.events.on('g-get-gas-upgrade-safehouse', () => updateGasBuySafeHouseRef.current?.());

      gameRef.current?.events.on('g-get-networth', () => calculateNetworthRef.current?.());

      gameRef.current?.events.on('g-buy-goon', (data) => buyGoonRef.current?.(data));

      gameRef.current?.events.on('g-buy-thug', (data) => buyThugRef.current?.(data));

      gameRef.current?.events.on('g-enable-thug-sales-tracking', () => {
        enableThugSalesTracking();
      });

      gameRef.current?.events.on('g-disable-thug-sales-tracking', () => {
        disableThugSalesTracking();
      });

      gameRef.current?.events.on('g-buy-gangster', (data) => buyGangsterRef.current?.(data));

      gameRef.current?.events.on('g-get-gas-mint', () => updateGasMintRef.current?.());

      gameRef.current?.events.on('g-update-war-machines', (data) => updateWarMachineRef.current?.(data));

      gameRef.current?.events.on('g-get-game-play', () => updateGamePlayRef.current?.());

      gameRef.current?.events.on('g-get-season', () => updateSeasonRef.current?.());

      gameRef.current?.events.on('g-get-rank', (data) => updateRankRef.current?.(data));

      gameRef.current.events?.on('g-get-end-game-reward', () => getRewardRef.current?.());

      gameRef.current?.events.on('g-get-claimed-free-thugs', () => getClaimFreeThugRef.current?.());

      gameRef.current?.events.on('g-get-user-away-reward', () => updateUserAwayRewardRef.current?.());

      gameRef.current?.events.on('g-get-claimable-status', () => updateClaimableStatusRef.current?.());

      gameRef.current?.events.on('g-get-claim-time', () => updateClaimTimeRef.current?.());

      gameRef.current?.events.on('g-get-wallet-nft-balance', () => updateWalletNFTBalanceRef.current?.());

      gameRef.current?.events.on('g-get-wallet-nft-unstaked', () => updateWalletNFTUnstakedRef.current?.());

      gameRef.current?.events.on('g-deposit-nft', ({ amount }) => stake(amount));

      gameRef.current?.events.on('g-update-last-time-seen-war-result', () => updateLastTimeSeenWarRef.current?.());

      gameRef.current?.events.on('g-get-war-history-latest', () => updateWarHistoryLatestRef.current?.());

      gameRef.current?.events.on('g-get-balances-for-withdraw', () => updateBalanceForWithdrawRef.current?.());

      gameRef.current?.events.on('g-withdraw-eth', ({ amount, address }) =>
        transfer({ amount, address, tokenType: 'ETH' })
      );

      gameRef.current?.events.on('g-withdraw-nft', ({ amount, address }) => {
        transfer({ amount, address, tokenType: 'NFT' });
      });

      gameRef.current?.events.on('g-withdraw-token', ({ amount, address }) => {
        transfer({ amount, address, tokenType: 'GOLD' });
      });

      gameRef.current?.events.on('g-check-user-completed-tutorial', () => checkUserCompletedTutorialRef.current?.());

      gameRef.current?.events.on('g-check-user-loaded', () => {
        gameRef.current?.events.emit('g-user-info-loaded');
      });

      gameRef.current?.events.emit('g-user-info-loaded');

      gameRef.current.events.on('g-get-auction-items', () => updateAuctionItemRef.current?.());

      gameRef.current.events.on('g-create-bidding', (data) => createBiddingRef.current?.(data));

      gameRef.current.events.on('g-get-bidding-history', () => updateBiddingHistoryRef.current?.());

      gameRef.current.events.on('g-get-auction-item-bidding-history', (data) =>
        updateAuctionItemBiddingHistoryRef.current?.(data)
      );

      gameRef.current.events.on('g-get-auction-item-bidding-history-detail', (data) =>
        updateAuctionItemBiddingHistoryDetailRef.current?.(data)
      );

      gameRef.current.events.on('g-get-auction-end-time', () => updateAuctionEndTimeRef.current?.());

      gameRef.current.events.on('g-get-next-auction-prize', () => updateNextAuctionPrizeRef.current?.());

      gameRef.current.events.on('g-tutorial-end', () => endTutorialRef.current?.());

      gameRef.current.events.on('g-get-claim-cool-down', () => updateClaimCoolDownRef.current?.());

      gameRef.current.events.on('g-get-deposit-address', () => updateDepositAddressRef.current?.());

      gameRef.current.events.on('g-buy-crypto-with-card', () => openMoonPayRef.current?.());

      gameRef.current.events.on('g-fund-wallet', (data) => fundWalletRef.current?.(data));

      gameRef.current.events.on('g-get-war-upgrade-explain', () => updateWarUpgradeExplainRef.current?.());

      gameRef.current.events.on('g-get-machines', () => updateMachineRef.current?.());

      gameRef.current.events.on('g-get-workers', () => updateWorkerRef.current?.());

      gameRef.current.events.on('g-get-buildings', () => updateBuildingRef.current?.());

      gameRef.current.events.on('g-get-thugs', () => updateThugRef.current?.());

      return () => {
        try {
          // gameRef.current?.scene.destroy();
        } catch (err) {
          console.error(err);
        }
      };
    }
  }, [loaded]);

  // effects
  useEffect(() => {
    onSeasonStatusChanged.current?.();
  }, [activeSeason?.status]);

  useEffect(() => {
    calculateClaimableRewardRef.current?.();
  }, [gamePlay?.startXTokenCountingTime, gamePlay?.pendingXToken, dailyXToken]);

  useEffect(() => {
    updatePoorTokenBalanceRef.current?.();
  }, [gamePlay?.poorTokenBalance, gamePlay?.startPoorTokenCountingTime]);

  useEffect(() => {
    updateAnimationAssetsRef.current?.();
  }, [numberOfWorkers, numberOfMachines, numberOfThugs]);

  useEffect(() => {
    gameRef.current?.events.emit('g-game-sound-changed', { sound });
  }, [sound]);

  useEffect(() => {
    updateBalanceRef.current?.();
  }, [ETHBalance, tokenBalance]);

  useEffect(() => {
    updateXTokenBalanceRef.current?.();
  }, [xTokenBalance]);

  useEffect(() => {
    const calculateNetworthInterval = setInterval(() => {
      calculateNetworthRef?.current?.();
    }, 5 * 60 * 1000);

    return () => clearInterval(calculateNetworthInterval);
  }, []);

  useEffect(() => {
    calculateNetworthRef?.current?.();
  }, [networth]);

  useEffect(() => {
    if (activeSeason?.auctionState?.activeAuctionId) {
      gameRef.current?.events?.emit('g-auction-changed');
    }
  }, [activeSeason?.auctionState?.activeAuctionId]);

  useEffect(() => {
    updateNextAuctionPrizeRef.current?.();
  }, [nextAuctionPrizePool]);

  useEffect(() => {
    if (listeningMachine) {
      updateMachineRef.current?.();
    }
  }, [listeningMachine, activeSeason?.machineSold]);

  useEffect(() => {
    if (listeningWorker) {
      updateWorkerRef.current?.();
    }
  }, [listeningWorker, activeSeason?.workerSold]);

  useEffect(() => {
    if (listeningBuilding) {
      updateBuildingRef.current?.();
    }
  }, [listeningBuilding, activeSeason?.buildingSold]);

  useEffect(() => {
    if (listeningThug) {
      updateThugRef.current?.();
    }
  }, [listeningThug, activeSeason?.thugSold]);

  const lastClaimTimeUnix = gamePlay?.lastClaimTime?.toDate()?.getTime();
  useEffect(() => {
    updateClaimTimeRef.current?.();
  }, [lastClaimTimeUnix]);

  useEffect(() => {
    calculateNetworthHouseRef.current?.();
  }, [networth, gamePlay?.startNetworthCountingTime]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={`${windowHeight}px`}>
      <Box
        position="relative"
        id="game-container"
        width="100vw"
        height={`${windowHeight}px`}
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={
          showBg
            ? {
                backgroundImage: {
                  xs: 'url(/images/bg-login-vertical.webp)',
                  sm: 'url(/images/bg-login-5x4.webp)',
                  md: 'url(/images/bg-login.webp)',
                },
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                '& canvas': { position: 'absolute' },
                '&::after': { content: '""', backgroundColor: 'rgba(0, 0, 0, 0.4)', width: '100%', height: '100%' },
              }
            : {}
        }>
        {showBg && (
          <>
            <Box position="absolute" top={0} left={0} width="100%" height="100%" zIndex={10} display="flex">
              <Box mt="50vh" p={2} width="100%" display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Box
                  width="100px"
                  mb="14vh"
                  sx={{
                    '& img': {
                      width: '100%',
                      animationName: 'spin',
                      animationDuration: '5000ms',
                      animationIterationCount: 'infinite',
                      animationTimingFunction: 'linear',
                    },
                  }}>
                  <img src="/images/icons/loading.png" />
                </Box>
                <Box height={48}>
                  {userCanReload && (
                    <Box
                      alignSelf="center"
                      position="relative"
                      sx={{ cursor: 'pointer', userSelect: 'none' }}
                      onMouseDown={() => setMouseDown(true)}
                      onMouseUp={() => setMouseDown(false)}
                      onClick={() => {
                        logAnalyticsEvent('user_reload', {
                          loading_duration: (Date.now() - startLoadingTime) / 1000,
                        });
                        logout();
                        // window.location.reload();
                      }}>
                      <Box width="120px" sx={{ '& img': { width: '100%' } }}>
                        <img
                          draggable={false}
                          src={mouseDown ? '/images/button-blue-pressed.png' : '/images/button-blue.png'}
                          alt="button"
                        />
                      </Box>
                      <Box position="absolute" top="45%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
                        <Typography
                          fontSize={20}
                          fontWeight={700}
                          color="white"
                          fontFamily="WixMadeforDisplayBold"
                          sx={{ userSelect: 'none' }}>
                          Retry
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box height="100px"></Box>
    </Box>
  );
};

export default Game;
