import Popup from './Popup';
import Button from '../button/Button';
import PopupProcessing from './PopupProcessing';
import PopupConfirmStarterPack from './PopupConfirmStarterPack';
import configs from '../../configs/configs';
import { customFormat, formatTimeDigit } from '../../../../utils/numbers';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';

const { width, height } = configs;

class PopupStarterPack extends Popup {
  packId = 'default';
  endTimeUnix = null;
  available = false;
  ethBalance = 0;
  goonsCount = 0;
  gangstersCount = 0;
  thugsCount = 0;
  priceEth = 0.1;

  constructor(scene) {
    super(scene, 'popup-starter-pack', { title: 'Discount!', ribbon: 'ribbon-extra' });

    const unitsCounterY = this.popup.y + 145;
    const countdownY = unitsCounterY + 230;

    this.popupBuyProcessing = new PopupProcessing(scene, {
      sound: 'spin-result-sound',
      completedEvent: 's-buy-starter-pack-completed',
      completedIcon: 'icon-buy-starter-pack-done',
      failedIcon: 'icon-buy-starter-pack',
      description: ``,
    });
    scene.add.existing(this.popupBuyProcessing);
    this.popupConfirm = new PopupConfirmStarterPack(scene, this, {
      onConfirm: () => {
        this.popupBuyProcessing.initLoading(`Purchasing beginners pack.\nPlease, wait`);

        scene.events.emit('s-buy-starter-pack', {
          packId: this.packId,
          goonsQuantity: this.goonsCount,
          gangstersQuantity: this.gangstersCount,
          thugsQuantity: this.thugsCount,
        });
      },
    });
    scene.add.existing(this.popupConfirm);

    this.numberOfGoons = scene.add
      .text(width / 2 + 10 - this.popup.width * 0.3, unitsCounterY, ``, {
        fontSize: fontSizes.medium,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.numberOfGangsters = scene.add
      .text(width / 2 + 10, unitsCounterY, ``, {
        fontSize: fontSizes.medium,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.numberOfThugs = scene.add
      .text(width / 2 + 10 + this.popup.width * 0.3, unitsCounterY, ``, {
        fontSize: fontSizes.medium,
        color: colors.brown,
        fontFamily: fontFamilies.extraBold,
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.numberOfGoons);
    this.add(this.numberOfGangsters);
    this.add(this.numberOfThugs);

    this.starterPackCountdownText = scene.add
      .text(width / 2 - 190, countdownY, '--d --h --m --s', {
        fontSize: fontSizes.large,
        fontFamily: fontFamilies.bold,
        color: colors.black,
      })
      .setOrigin(0, 0.5);
    this.add(this.starterPackCountdownText);

    this.buttonBuyNow = new BuyNowButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue-med-long',
      'button-blue-med-long-pressed',
      () => {
        this.close();
        this.popupConfirm.open();
      },
      { sound: 'buy', disabledImage: 'button-med-long-disabled' }
    );
    this.add(this.buttonBuyNow);

    scene.events.on('s-set-balances', ({ ETHBalance }) => {
      this.ethBalance = ETHBalance;
      this.updateBtnDisabledStatus();
    });
    scene.events.on('s-set-starter-pack-available', ({ starterPackAvailable }) => {
      this.available = starterPackAvailable;
      if (!starterPackAvailable) this.close();
    });
    scene.events.on('s-set-starter-pack-config', (packsConfig) => {
      const { priceEth, numberOfGoons, numberOfGangsters, numberOfThugs, availableUntil } = packsConfig[this.packId];
      this.goonsCount = numberOfGoons;
      this.gangstersCount = numberOfGangsters;
      this.thugsCount = numberOfThugs;
      this.priceEth = priceEth;
      this.endTimeUnix = availableUntil.toDate().getTime();
      this.priceEth = this.priceEth;
      this.popupConfirm.updatePrices({
        ethPriceText: `${customFormat(this.priceEth, 3)}`,
      });
      this.buttonBuyNow.updatePrices({ ethPrice: this.priceEth });
      this.updateBtnDisabledStatus();
      this.countdown();

      this.popupConfirm.updateQuantity({
        goonQuantity: numberOfGoons,
        gangsterQuantity: numberOfGangsters,
        thugQuantity: numberOfThugs,
      });
      this.numberOfGoons.text = `${customFormat(numberOfGoons, 2)}`;
      this.numberOfGangsters.text = `${customFormat(numberOfGangsters, 2)}`;
      this.numberOfThugs.text = `${customFormat(numberOfThugs, 2)}`;
    });
  }

  onOpen() {
    this.scene.events.emit('s-get-market-data');
    this.scene.events.emit('s-get-starter-pack-config');
    this.scene.events.emit('s-get-starter-pack-available', { packId: this.packId });
  }

  cleanup() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  showEndTime() {
    const now = Date.now();
    const diff = this.endTimeUnix - now;

    if (diff <= 0) {
      this.starterPackCountdownText.setVisible(false);
      clearInterval(this.interval);
      this.interval = null;
      return;
    }

    const diffInSeconds = Math.max(diff / 1000, 0);
    const days = Math.floor(diffInSeconds / 86400);
    const hours = Math.floor((diffInSeconds % 86400) / 3600);
    const mins = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.round(diffInSeconds % 60);

    this.starterPackCountdownText.setVisible(true);
    this.starterPackCountdownText.text = `${formatTimeDigit(days)}d ${formatTimeDigit(hours)}h ${formatTimeDigit(
      mins
    )}m ${formatTimeDigit(seconds)}s`;
  }

  countdown() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.endTimeUnix) {
      this.interval = setInterval(() => this.showEndTime(), 1000);
    }
  }

  updateBtnDisabledStatus() {
    this.buttonBuyNow.setDisabledState(this.priceEth > this.ethBalance);
    this.scene.events.emit('update-can-buy-starter-pack', {
      userCanBuy: this.available && !this.buttonBuyNow.disabled,
    });
  }
}

export default PopupStarterPack;

class BuyNowButton extends Button {
  constructor(scene, x, y, defaultImage, pressedImage, onClick, { sound, disabledImage } = {}) {
    super(scene, x, y, defaultImage, pressedImage, onClick, { sound, disabledImage });

    // texts
    this.textStrokeColor = '#0004a0';
    this.buyNowText = scene.add
      .text(0, -55, 'BUY NOW', {
        fontSize: '52px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5);
    this.buyNowText.setStroke(this.textStrokeColor, 10);
    this.add(this.buyNowText);

    this.ethIcon = scene.add.image(120, 30, 'icon-eth').setDisplaySize(82, 82);
    this.ethPrice = scene.add
      .text(this.ethIcon.x - 50, 30, '0', {
        fontSize: '82px',
        color: '#fff',
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    this.ethPrice.setStroke(this.textStrokeColor, 10);
    this.add(this.ethPrice);
    this.add(this.ethIcon);
  }

  updatePrices({ ethPrice }) {
    this.ethPrice.text = `${customFormat(ethPrice, 3)}`;
  }
}
